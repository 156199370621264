<template>
  <a-drawer width="35%" :label-col="4" :wrapper-col="14" :visible="open" @cancel="onClose" @close="onClose">
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :label-col="labelCol" :wrapper-col="wrapperCol" :model="form" :rules="rules">
      <a-form-model-item :label="this.$t('线下店铺套餐详情.商品套餐')" prop="packageId">
        <a-select style="width: 100%" v-model="form.packageId" placeholder="请选择主营行业">
          <a-select-option v-for="(item, index) in packageList" :value="item.id" :key="index">
            {{ item.remark }}
          </a-select-option>
        </a-select>
      </a-form-model-item>

      <a-form-model-item :label="$t('线下店铺套餐详情.套餐主标题')" prop="title">
        <a-input v-model="form.title" :maxLength="50" :placeholder="$t('通用.输入.请输入')+$t('线下店铺套餐详情.套餐主标题')"/>
      </a-form-model-item>
      <a-form-model-item :label="$t('线下店铺套餐详情.套餐副标题')" prop="subtitle">
        <a-input v-model="form.subtitle" :maxLength="50" :placeholder="$t('通用.输入.请输入')+$t('线下店铺套餐详情.套餐副标题')"/>
      </a-form-model-item>
      <a-form-model-item :label="$t('线下店铺套餐详情.套餐详情价格')" prop="price">
        <a-input v-model="form.price" :maxLength="50" :placeholder="$t('通用.输入.请输入')+$t('线下店铺套餐详情.套餐详情价格')"/>
      </a-form-model-item>
      <a-form-model-item :label="$t('通用.文本.排序')" prop="sort">
        <a-input-number :maxLength="6" style="width: 50%" @change="limitSort" v-model="form.sort"
                        :placeholder="$t('通用.输入.请输入')+$t('通用.文本.排序')"/>
      </a-form-model-item>
      <a-modal :visible="previewImageVisible" :footer="null" @cancel="previewImageCancel">
        <img alt="example" style="width: 100%" :src="previewUrl"/>
      </a-modal>
      <a-modal :visible="previewVideoVisible" :footer="null" @cancel="previewVideoCancel">
        <video style="width: 100%" :src="previewUrl" ref="myVideo"
               :poster="previewUrl + '?x-oss-process=video/snapshot,t_0,f_jpg'" :controls="true"></video>
      </a-modal>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            {{ $t('通用.按钮.保存') }}
          </a-button>
          <a-button type="dashed" @click="cancel">
            {{ $t('通用.按钮.取消') }}
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import {getSysDetail, addSysDetail, updateSysDetail} from '@/api/thali/sysDetail'
import {mapGetters} from 'vuex'
import CustomDictTag from "@/components/DictCustomTag";
import {generateFilePath, uploadObject} from "@/api/tool/oss";
import { pageSys,listSys, delSys,updateReservation } from '@/api/thali/sys'


export default {
  name: 'CreateForm',
  props: {},
  components: {
    CustomDictTag,
  },
  data() {
    return {
      packageList: [],
      labelCol: {span: 4},
      wrapperCol: {span: 14},
      submitLoading: false,
      formTitle: '',
      previewImageVisible: false,
      previewUrl: '',
      previewVideoVisible: false,
      loading: '',
      // 表单参数
      form: {
        id: null,

        sysId: null,

        packageId: null,

        title: null,

        subtitle: null,

        price: null,

        sort: null,

        createTime: null,

        remark: null,

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        title: [
          {required: true, message: this.$t('线下店铺套餐详情.套餐主标题') + this.$t('通用.文本.不能为空'), trigger: 'blur'}
        ],
        subtitle: [
          {required: true, message: this.$t('线下店铺套餐详情.套餐副标题') + this.$t('通用.文本.不能为空'), trigger: 'blur'}
        ],
        price: [
          {required: true, message: this.$t('线下店铺套餐详情.套餐详情价格') + this.$t('通用.文本.不能为空'), trigger: 'blur'}
        ]
      }
    }
  },
  filters: {},
  created() {
    this.getPackageList()
  },
  computed: {
    ...mapGetters(['customDict'])
  },
  watch: {},
  mounted() {
  },
  methods: {
    onClose() {
      this.open = false
      this.reset()
    },
    // 取消按钮
    cancel() {
      this.open = false
      this.reset()
    },
    previewImageCancel() {
      this.previewImageVisible = false;
    },
    previewVideoCancel() {
      this.previewVideoVisible = false;
    },
    // 表单重置
    reset() {
      this.formType = 1
      this.form = {
        id: null,
        sysId: null,
        packageId: null,
        title: null,
        subtitle: null,
        price: null,
        sort: null,
        createTime: null,
        remark: null,
      }

    },
    /** 新增按钮操作 */
    handleAdd(row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = this.$t('通用.文本.添加')
    },
    /** 修改按钮操作 */
    handleUpdate(row, ids) {
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getSysDetail({"id": id}).then(response => {
        this.form = response.data
        this.open = true
        this.formTitle = this.$t('通用.文本.修改')
      })
    },
    getPackageList() {
      listSys().then(response => {
        this.packageList = response.data
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          let message = '';
          if (this.form.id !== undefined && this.form.id !== null) {
            message = this.$t('通用.文本.修改成功')
            updateSysDetail(this.form).then(response => {
              this.$message.success(
                message,
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            message = this.$t('通用.文本.修改成功')
            addSysDetail(this.form).then(response => {
              this.$message.success(
                message,
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
