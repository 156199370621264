import request from '@/utils/request'


// 查询线下店铺套餐详情列表
export function listSysDetail(query) {
  return request({
    url: '/thali/sys/detail/list',
    method: 'get',
    params: query
  })
}

// 查询线下店铺套餐详情分页
export function pageSysDetail(query) {
  return request({
    url: '/thali/sys/detail/page',
    method: 'get',
    params: query
  })
}

// 查询线下店铺套餐详情详细
export function getSysDetail(data) {
  return request({
    url: '/thali/sys/detail/detail',
    method: 'get',
    params: data
  })
}

// 新增线下店铺套餐详情
export function addSysDetail(data) {
  return request({
    url: '/thali/sys/detail/add',
    method: 'post',
    data: data
  })
}

// 修改线下店铺套餐详情
export function updateSysDetail(data) {
  return request({
    url: '/thali/sys/detail/edit',
    method: 'post',
    data: data
  })
}

// 删除线下店铺套餐详情
export function delSysDetail(data) {
  return request({
    url: '/thali/sys/detail/delete',
    method: 'post',
    data: data
  })
}
