import request from '@/utils/request'


// 查询线下店铺套餐内容列表
export function listSys(query) {
  return request({
    url: '/thali/sys/list',
    method: 'get',
    params: query
  })
}

// 查询线下店铺套餐内容分页
export function pageSys(query) {
  return request({
    url: '/thali/sys/page',
    method: 'get',
    params: query
  })
}

// 查询线下店铺套餐内容详细
export function getSys(data) {
  return request({
    url: '/thali/sys/detail',
    method: 'get',
    params: data
  })
}

// 新增线下店铺套餐内容
export function addSys(data) {
  return request({
    url: '/thali/sys/add',
    method: 'post',
    data: data
  })
}

// 修改线下店铺套餐内容
export function updateSys(data) {
  return request({
    url: '/thali/sys/edit',
    method: 'post',
    data: data
  })
}

// 删除线下店铺套餐内容
export function delSys(data) {
  return request({
    url: '/thali/sys/delete',
    method: 'post',
    data: data
  })
}

// 是否需要预约
export function updateReservation(data) {
  return request({
    url: '/thali/sys/updateReservation',
    method: 'post',
    data: data
  })
}
// 是否需要添加人员
export function updatePerson(data) {
  return request({
    url: '/thali/sys/updatePerson',
    method: 'post',
    data: data
  })
}
